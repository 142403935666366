"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// public/source/serviceWorkerInit.js
async function serviceWorkerInit() {
  if (!navigator.serviceWorker) {
    console.warn("Service worker not available");
    document.documentElement.dataset.serviceWorkerIsLoading = String(false);
    return null;
  }
  const version = sessionStorage.getItem("REACT_APP_SERVICE_WORKER_VERSION");
  const channel2 = getChannel();
  const registrations = await navigator.serviceWorker.getRegistrations();
  const activeRegistrations = registrations.find((registration) => registration.active);
  navigator.serviceWorker.register(`/service-worker.js`, { scope: "/" }).then((reg) => {
    console.warn(reg.active, localStorage.getItem("swVersion"), version);
    if (reg.active && localStorage.getItem("swVersion") === version) {
      document.documentElement.dataset.serviceWorkerIsLoading = String(false);
    }
    reg.update();
  }).catch((e) => {
    console.warn(e);
    document.documentElement.dataset.serviceWorkerIsLoading = String(false);
  }).finally(() => {
    document.documentElement.dataset.serviceWorkerIsLoading = String(false);
    const initMessage = {
      serviceWorker: {
        message: "init"
      }
    };
    channel2.postMessage(initMessage);
    console.warn(localStorage.getItem("swVersion"), version);
    if (localStorage.getItem("swVersion") === version) {
      document.documentElement.dataset.serviceWorkerIsLoading = String(false);
    }
    const browserUsed = getBrowserUsed();
    if (!(browserUsed.name === "Safari" && browserUsed.version.match(/^(10|11|12|13|14|15)/))) {
      getCurrentStand().then((currentStand) => {
        const script = document.createElement("script");
        script.src = `/webPushes/${currentStand}/edna-web-widget.js`;
        script.id = "edna";
        document.body.appendChild(script);
      });
    }
    let message = {
      serviceWorker: {
        message: "networkIsOffline"
      }
    };
    if (window.navigator.onLine) {
      message = {
        serviceWorker: {
          message: "networkIsOnline"
        }
      };
    }
    channel2.postMessage(message);
  });
  if (activeRegistrations && localStorage.getItem("swVersion") === version) {
    document.documentElement.dataset.serviceWorkerIsLoading = String(false);
  }
}
async function getCurrentStand() {
  const response = await fetch("/stands.json");
  const stands = await response.json();
  if (stands.test.url === location.origin || stands.test.partners.includes(location.origin)) {
    return "test";
  }
  if (stands.cert.url === location.origin || stands.cert.partners.includes(location.origin)) {
    return "cert";
  }
  if (stands.prod.url === location.origin || stands.prod.partners.includes(location.origin)) {
    return "prod";
  }
  return "test";
}
function getChannel() {
  const browserUsed = getBrowserUsed();
  if (!(browserUsed.name === "Safari" && browserUsed.version.match(/^(10|11|12|13|14|15)/)))
    return new window.BroadcastChannel("service-worker");
  return {
    postMessage: (message) => {
      var _a, _b;
      (_b = (_a = navigator.serviceWorker) == null ? void 0 : _a.controller) == null ? void 0 : _b.postMessage(message);
    },
    addEventListener(type, listener) {
      var _a;
      if ("serviceWorker" in navigator) {
        (_a = navigator.serviceWorker) == null ? void 0 : _a.addEventListener(type, listener);
      }
    }
  };
}
var getBrowserUsed = () => {
  const result = { name: "", version: "" };
  if (navigator.userAgent.includes("Firefox/")) {
    const [name, version] = getNameAndVersionOfBrowser("Firefox");
    result.name = name;
    result.version = version;
  } else if (navigator.userAgent.includes("Edg/")) {
    const [name, version] = getNameAndVersionOfBrowser("Edg");
    result.name = name;
    result.version = version;
  } else if (navigator.userAgent.includes("YaBrowser/")) {
    const [name, version] = getNameAndVersionOfBrowser("YaBrowser");
    result.name = name;
    result.version = version;
  } else if (navigator.userAgent.includes("Chrome/")) {
    const [name, version] = getNameAndVersionOfBrowser("Chrome");
    result.name = name;
    result.version = version;
  } else if (navigator.userAgent.includes("Safari/")) {
    const [name, version] = getNameAndVersionOfBrowser("Safari");
    result.name = name;
    result.version = version;
  }
  return result;
};
function getNameAndVersionOfBrowser(browserName) {
  var _a, _b, _c, _d;
  const version = (_b = (_a = navigator.userAgent.match(new RegExp("Version\\/(?<version>[0-9.]+)"))) == null ? void 0 : _a.groups) == null ? void 0 : _b.version;
  if (version) {
    return [browserName, version];
  }
  return ((_d = (_c = navigator.userAgent.match(new RegExp(`${browserName}/[0-9.]+`))) == null ? void 0 : _c.pop()) == null ? void 0 : _d.trim().split("/")) || ["", ""];
}

// public/source/pullToRefreshInit.js
var onRefresh = () => {
  const message = {
    app: "updateData"
  };
  window.postMessage(message, {
    targetOrigin: window.location.origin
  });
  if (document.documentElement.dataset.isLoading === "true" || document.documentElement.dataset.serviceWorkerIsLoading === "true") {
    window.location.reload();
  } else {
    return new Promise((resolve) => {
      window.addEventListener("message", (evt) => {
        var _a;
        if (((_a = evt.data) == null ? void 0 : _a.app) === "isDataUpdated" && evt.origin === window.location.origin) {
          resolve();
        }
      });
    });
  }
};
var PullToRefresh = class {
  constructor() {
    __publicField(this, "showModalsCount", 0);
    __publicField(this, "pullToRefreshInitConfig", {
      mainElement: "body",
      onRefresh,
      iconRefreshing: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path clip-rule="evenodd"
                              d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8H14.6667C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333V0Z"
                              fill="#6E7279"/>
                        </svg>`,
      iconArrow: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M3.14745 6.86061C2.95182 6.66541 2.95164 6.34852 3.14706 6.1531L3.85427 5.44588C4.04934 5.25082 4.36553 5.25059 4.56087 5.44538L8.00404 8.50317L11.4394 5.4567C11.6348 5.26209 11.9508 5.2624 12.1458 5.45739L12.8531 6.16465C13.0485 6.36012 13.0483 6.67712 12.8525 6.8723L8.35759 11.3535C8.16233 11.5488 7.84575 11.5488 7.65049 11.3535L3.14745 6.86061Z"
                            fill="#A6AAB1"/>
                    </svg>`,
      distReload: 56,
      distIgnore: 30
    });
    __publicField(this, "activePullToRefresh", null);
    __publicField(this, "showModal", () => {
      this.showModalsCount = this.showModalsCount + 1;
      if (this.showModalsCount && this.activePullToRefresh) {
        this.activePullToRefresh.destroy();
        this.activePullToRefresh = null;
      }
    });
    __publicField(this, "hideModal", () => {
      this.showModalsCount = !this.showModalsCount ? 0 : this.showModalsCount - 1;
      if (!this.showModalsCount && !this.activePullToRefresh) {
        this.activePullToRefresh = window.PullToRefresh.init(this.pullToRefreshInitConfig);
      }
    });
    this.activePullToRefresh = window.PullToRefresh.init(this.pullToRefreshInitConfig);
  }
};
function pullToRefreshInit() {
  window.pullToRefresh = new PullToRefresh();
}

// public/source/index.js
var channel = getChannel();
if ("caches" in window) {
  Promise.all([caches.open("js"), caches.open("css"), caches.open("html")]).then(() => serviceWorkerInit().then());
}
pullToRefreshInit();
var toastNames = {
  networkOnline: "networkOnline",
  networkOffline: "networkOffline",
  networkStillOffline: "networkStillOffline"
};
var screenNames = {
  networkProblem: "networkProblem",
  mfError: "mfError"
};
var toastIconsNames = {
  success: "success",
  info: "info"
};
var toastStatus = {
  success: "success",
  error: "error",
  warning: "warning"
};
var toastIconsStatusClasses = {
  [toastStatus.success]: "notification-toast-icon__success",
  [toastStatus.error]: "notification-toast-icon__error",
  [toastStatus.warning]: "notification-toast-icon__warning"
};
var toastHtml = `
    <div class="notification-toast-content">
        <div class="notification-toast-icon">
        </div>
        <div class="notification-toast-text"></div>
    </div>
`;
var toastIcons = {
  [toastIconsNames.success]: `
         <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1420_7)">
                <rect width="12" height="12" rx="6" fill="white"></rect>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM9.81448 4.43307C9.91211 4.5307 9.91211 4.68899 9.81448 4.78662L5.67306 8.92804C5.57543 9.02567 5.41713 9.02567 5.3195 8.92804L2.67808 6.28662C2.58045 6.18899 2.58045 6.0307 2.67808 5.93307L3.31448 5.29667C3.41211 5.19904 3.5704 5.19904 3.66803 5.29667L5.49628 7.12492L8.82453 3.79667C8.92216 3.69904 9.08045 3.69904 9.17808 3.79667L9.81448 4.43307Z"
                      fill="#A7AFBC"></path>
            </g>
            <defs>
                <clipPath id="clip0_1420_7">
                    <rect width="12" height="12" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    `,
  [toastIconsNames.info]: `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1420_6)">
                <rect width="12" height="12" rx="6" fill="white"></rect>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6.69999 8.80244C6.69999 9.18904 6.38659 9.50244 5.99999 9.50244C5.61339 9.50244 5.29999 9.18904 5.29999 8.80244C5.29999 8.41584 5.61339 8.10244 5.99999 8.10244C6.38659 8.10244 6.69999 8.41584 6.69999 8.80244ZM5.54999 6.99758C5.41192 6.99758 5.29999 6.88565 5.29999 6.74758V2.74756C5.29999 2.60949 5.41192 2.49756 5.54999 2.49756H6.44999C6.58806 2.49756 6.69999 2.60949 6.69999 2.74756V6.74758C6.69999 6.88565 6.58806 6.99758 6.44999 6.99758H5.54999Z"
                      fill="#A7AFBC"></path>
            </g>
            <defs>
                <clipPath id="clip0_1420_6">
                    <rect width="12" height="12" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    `
};
var localization = {
  screens: {
    [screenNames.networkProblem]: {
      title: {
        en: "No network connection",
        ru: "\u041D\u0435\u0442 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u044F \u043A \u0441\u0435\u0442\u0438"
      },
      description: {
        en: "Check your internet access",
        ru: "\u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u0432\u0430\u0448 \u0434\u043E\u0441\u0442\u0443\u043F \u043A \u0438\u043D\u0442\u0435\u0440\u043D\u0435\u0442\u0443"
      },
      button: {
        en: "To retry",
        ru: "\u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u044C \u043F\u043E\u043F\u044B\u0442\u043A\u0443"
      }
    },
    [screenNames.mfError]: {
      title: {
        en: "Failed to load data",
        ru: "\u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0434\u0430\u043D\u043D\u044B\u0435"
      },
      description: {
        en: "Try again",
        ru: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u043F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u044C"
      },
      button: {
        en: "To retry",
        ru: "\u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u044C \u043F\u043E\u043F\u044B\u0442\u043A\u0443"
      }
    }
  },
  toasts: {
    [toastNames.networkStillOffline]: {
      text: {
        en: "There is no internet connection.",
        ru: "\u041E\u0442\u0441\u0443\u0442\u0441\u0442\u0432\u0443\u0435\u0442 \u0438\u043D\u0442\u0435\u0440\u043D\u0435\u0442 \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u0435."
      }
    },
    [toastNames.networkOnline]: {
      text: {
        en: "Internet connection has been restored.",
        ru: "\u0418\u043D\u0442\u0435\u0440\u043D\u0435\u0442 \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u0435 \u0432\u043E\u0437\u043E\u0431\u043D\u043E\u0432\u0438\u043B\u043E\u0441\u044C."
      }
    },
    [toastNames.networkOffline]: {
      text: {
        en: "Internet connection was lost.",
        ru: "\u0418\u043D\u0442\u0435\u0440\u043D\u0435\u0442 \u0441\u043E\u0435\u0434\u0438\u043D\u0435\u043D\u0438\u0435 \u043F\u0440\u043E\u043F\u0430\u043B\u043E."
      }
    }
  }
};
var mfProblemScreen = document.querySelector(".problem-screen_mf-error");
var networkProblemScreen = document.querySelector(".problem-screen_network");
var toasts = {
  [toastNames.networkOnline]: {
    icon: toastIconsNames.success,
    status: toastStatus.success
  },
  [toastNames.networkOffline]: {
    icon: toastIconsNames.info,
    status: toastStatus.error
  },
  [toastNames.networkStillOffline]: {
    icon: toastIconsNames.info,
    status: toastStatus.warning
  }
};
var showNetworkOfflineToast = () => {
  showToast(toastNames.networkOffline);
  const networkIsOfflineMessage = {
    serviceWorker: {
      message: "networkIsOffline"
    }
  };
  channel.postMessage(networkIsOfflineMessage);
};
if (window.navigator.onLine) {
  const script = document.createElement("script");
  script.src = "https://vojs.group-ib.ru/build/main__76270912_731_2219.js";
  script.id = "group-ib";
  document.body.appendChild(script);
} else {
  window.addEventListener("online", () => {
    const groupIbScript = document.getElementById("group-ib");
    if (!groupIbScript) {
      const script = document.createElement("script");
      script.src = "https://vojs.group-ib.ru/build/main__76270912_731_2219.js";
      script.id = "group-ib";
      document.body.appendChild(script);
    }
  });
  showNetworkOfflineToast();
  showNetworkProblemScreen();
}
window.addEventListener("unhandledrejection", (e) => {
  if (/ChunkLoadError/.test(e.reason.name) || e.reason.code === "CSS_CHUNK_LOAD_FAILED") {
    deleteCaches().then(() => {
      window.location.reload();
    });
  } else {
    showErrorScreen();
  }
});
window.addEventListener("error", function(e) {
  console.log("Global handler " + e.message);
});
window.addEventListener("beforeunload", () => {
  const messageClearData = {
    serviceWorker: {
      message: "clearData"
    }
  };
  channel.postMessage(messageClearData);
  sessionStorage.setItem("hasUpdate", "false");
});
channel.addEventListener("message", (evt) => {
  if (evt.origin === location.origin && evt.data.app && evt.data.app.message === "hasRequestsWithNetworkProblemChanged" && evt.data.app.messageData) {
    showNetworkProblemScreen();
  }
  if (evt.data.app && evt.data.app.message === "hasRequestsWithNetworkProblemChanged" && !evt.data.app.messageData) {
    networkProblemScreen.classList.remove("problem-screen_show");
  }
  if (evt.data.app && evt.data.app.message === "swStatus") {
    if (evt.data.app.messageData.install) {
    }
    if (evt.data.app.messageData.activate) {
      const swActivateVersion = evt.data.app.messageData.activate;
      console.warn(localStorage.getItem("swVersion"), swActivateVersion);
      if (localStorage.getItem("swVersion") !== swActivateVersion) {
        console.warn("reload=========================================");
        localStorage.setItem("swVersion", swActivateVersion);
      } else {
        document.documentElement.dataset.serviceWorkerIsLoading = String(false);
      }
    }
  }
});
var showNetworkStillOfflineToast = () => {
  showToast(toastNames.networkStillOffline);
};
var showNetworkOnlineToast = () => {
  showToast(toastNames.networkOnline);
  const networkIsOnlineMessage = {
    serviceWorker: {
      message: "networkIsOnline"
    }
  };
  channel.postMessage(networkIsOnlineMessage);
};
window.addEventListener("offline", showNetworkOfflineToast);
window.addEventListener("online", showNetworkOnlineToast);
channel.addEventListener("message", (evt) => {
  var _a;
  if (evt.origin === location.origin && evt.data.app && ((_a = evt.data.app) == null ? void 0 : _a.message) === "mfModuleIsFailedLoad") {
    showErrorScreen();
  }
});
function showErrorScreen() {
  const currentLanguage = localStorage.getItem("currentLanguage");
  mfProblemScreen.classList.add("problem-screen_show");
  const button = document.querySelector(".problem-screen_mf-error .problem-screen__button button");
  const title = document.querySelector(".problem-screen_mf-error .problem-screen__title");
  const description = document.querySelector(".problem-screen_mf-error .problem-screen__description");
  if (title) {
    title.innerHTML = currentLanguage ? localization.screens[screenNames.mfError].title[currentLanguage] : localization.screens[screenNames.mfError].title.ru;
  }
  if (description) {
    description.innerHTML = currentLanguage ? localization.screens[screenNames.mfError].description[currentLanguage] : localization.screens[screenNames.mfError].description.ru;
  }
  if (button) {
    button.innerHTML = currentLanguage ? localization.screens[screenNames.mfError].button[currentLanguage] : localization.screens[screenNames.mfError].button.ru;
    button.onclick = () => {
      if (!window.navigator.onLine) {
        showNetworkOfflineToast();
      } else {
        deleteCaches().then(() => {
          window.location.reload();
          mfProblemScreen.classList.remove("problem-screen_show");
        });
      }
    };
  }
}
function showNetworkProblemScreen() {
  networkProblemScreen.classList.add("problem-screen_show");
  const currentLanguage = localStorage.getItem("currentLanguage");
  const closeIcon = document.querySelector(".problem-screen_network .problem-screen__close-icon");
  const button = document.querySelector(".problem-screen_network .problem-screen__button button");
  const title = document.querySelector(".problem-screen_network .problem-screen__title");
  const description = document.querySelector(".problem-screen_network .problem-screen__description");
  if (title) {
    title.innerHTML = currentLanguage ? localization.screens[screenNames.networkProblem].title[currentLanguage] : localization.screens[screenNames.networkProblem].title.ru;
  }
  if (description) {
    description.innerHTML = currentLanguage ? localization.screens[screenNames.networkProblem].description[currentLanguage] : localization.screens[screenNames.networkProblem].description.ru;
  }
  if (button) {
    button.innerHTML = currentLanguage ? localization.screens[screenNames.networkProblem].button[currentLanguage] : localization.screens[screenNames.networkProblem].description.ru;
    button.onclick = () => {
      if (!window.navigator.onLine) {
        showNetworkStillOfflineToast();
      } else {
        const message = {
          serviceWorker: {
            message: "retryRequests"
          }
        };
        channel.postMessage(message);
      }
    };
  }
  if (closeIcon) {
    closeIcon.onclick = () => {
      if (!window.navigator.onLine) {
        showNetworkStillOfflineToast();
      } else {
        const message = {
          serviceWorker: {
            message: "retryRequestsInBackground"
          }
        };
        channel.postMessage(message);
      }
    };
  }
}
function hideToast(toast) {
  if (!toast.classList.contains("notification-toast_hide")) {
    toast.classList.remove("notification-toast_active");
    toast.classList.add("notification-toast_hide");
    const timer = setTimeout(() => {
      document.body.removeChild(toast);
      clearTimeout(timer);
    }, 300);
  }
}
function showToast(toastName) {
  var _a;
  const {
    icon,
    status
  } = toasts[toastName];
  const toast = document.createElement("div");
  if (!document.querySelector(`notification-toast-${status}`)) {
    toast.classList.add("notification-toast", `notification-toast-${status}`);
    toast.innerHTML = toastHtml;
    const iconNode = toast.querySelector(".notification-toast-icon");
    const currentLanguage = localStorage.getItem("currentLanguage");
    const textNode = toast.querySelector(".notification-toast-text");
    const text = currentLanguage ? localization.toasts[toastName].text[currentLanguage] : localization.toasts[toastName].text.ru;
    if (textNode) {
      textNode.innerHTML = text;
    }
    if (iconNode) {
      iconNode.innerHTML = toastIcons[icon];
      iconNode.classList.add(toastIconsStatusClasses[status]);
    }
    document.body.appendChild(toast);
    (_a = document.querySelectorAll(".notification-toast")) == null ? void 0 : _a.forEach((domToast) => {
      if (domToast !== toast)
        hideToast(domToast);
    });
    const topTimer = setTimeout(() => {
      toast.classList.add("notification-toast_active");
    }, 270);
    const timer = setTimeout(() => {
      hideToast(toast);
      clearTimeout(topTimer);
      clearTimeout(timer);
    }, 2300);
  }
}
async function deleteCaches() {
  if (caches) {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName)));
  }
}
window.showToast = () => showToast(toastNames.networkOffline);
window.showErrorScreen = showErrorScreen;
